import React from "react";
import { useLoader } from "@react-three/fiber";
import * as THREE from "three";

function Floor() {
  // Carica la texture principale dal percorso
  const texture = useLoader(THREE.TextureLoader, "/texture_floor.jpg");

  // Carica una bumpMap o normalMap per migliorare i dettagli della superficie
  const bumpMap = useLoader(THREE.TextureLoader, "/bump_floor.jpg");

  return (
    <mesh position={[0, -1, 0]} receiveShadow>
      {/* Geometria del pavimento */}
      <boxGeometry args={[25, 1, 25]} />

      {/* Materiale con texture applicata e dettagli aggiuntivi */}
      <meshStandardMaterial
        map={texture} // Applica la texture principale
        bumpMap={bumpMap} // Applica la bumpMap per l'illusione di imperfezioni
        bumpScale={0.3} // Controlla l'intensità della bumpMap
        roughness={0.7} // Superficie meno ruvida per un aspetto più "levigato"
        metalness={0.1} // Aumenta un po' il metallico per l'effetto industriale
      />
    </mesh>
  );
}

export default Floor;
