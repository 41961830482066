import React from "react";
import PropTypes from "prop-types";

const ConfirmModal = ({
  isOpen,
  title = "Conferma", // Set default value here
  message,
  onConfirm,
  onCancel,
}) => {
  if (!isOpen) return null;

  return (
    <div className="confirm-modal-overlay" onClick={onCancel}>
      <div className="confirm-modal" onClick={(e) => e.stopPropagation()}>
        {title && <h2>{title}</h2>}
        <p>{message}</p>
        <div className="confirm-modal-buttons">
          <button className="confirm" onClick={onConfirm}>
            Conferma
          </button>
          <button className="cancel" onClick={onCancel}>
            Annulla
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmModal;
