import React, { useState, useEffect, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import Lighting from "./Lighting";
import Floor from "../../GLTFJSX/Floor";
import { Stage, OrbitControls, Text } from "@react-three/drei"; // Import Text from drei
import { fetchMacchinari } from "../Services"; // Import the function from Services.js
import { getTableData } from "../../services/WebSocketTableState"; // Assicurati di importare getTableData se è gestito separatamente
import {
  connectWebSocket,
  waitForWebSocketOpen,
  closeWebSocket,
} from "../../services/WebsocketServices";

const Main = () => {
  const [machineryData, setMachineryData] = useState([]);
  const [showAxes, setShowAxes] = useState(false); // Stato per controllare la visibilità degli assi
  const [realTimeData, setRealTimeData] = useState([]); // Stato per memorizzare i dati in tempo reale

  useEffect(() => {
    // Connetti WebSocket quando il componente è montato
    connectWebSocket({
      onOpen: () => {
        console.log("WebSocket connesso");
      },
      onMessage: (message) => {
        // console.log("Messaggio ricevuto:", message);
        // Qui puoi gestire il messaggio ricevuto e aggiornare lo stato
      },
      onClose: () => {
        console.log("WebSocket disconnesso");
      },
      onError: (error) => {
        console.error("Errore WebSocket:", error);
      },
    });

    const loadMachinery = async () => {
      const data = await fetchMacchinari(); // Assuming fetchMacchinari fetches the machinery data
      setMachineryData(data);
    };

    loadMachinery();

    const intervalId = setInterval(() => {
      const updatedData = getTableData(); // Ottieni i dati dalla tabella
      setRealTimeData(updatedData); // Aggiorna lo stato con i nuovi dati
    }, 100); // 1000 ms = 1 secondo

    // Clean up the WebSocket connection on component unmount
    return () => {
      clearInterval(intervalId);
      closeWebSocket(); // Chiudi la connessione WebSocket quando il componente viene smontato
    };
  }, []); // Empty array means this effect runs only once when the component mounts

  const show3d = (fileName) => {
    if (!fileName) {
      console.error("Invalid fileName:", fileName);
      return null;
    }
    // Dynamically import the model JSX file
    return import(`../../GLTFJSX/${fileName}.jsx`).then(
      (module) => module.default
    );
  };

  return (
    <div>
      <div className="widget">
        {/* Pulsante per mostrare/nascondere gli assi */}
        <button
          onClick={() => setShowAxes((prev) => !prev)}
          style={{
            position: "absolute",
            top: "90%",
            left: "90%",
            zIndex: 100,
            padding: "10px 20px",
            fontSize: "12px",
          }}
        >
          {showAxes ? "Nascondi Assi" : "Mostra Assi"}
        </button>

        <Canvas
          className="fullscreen-canvas"
          shadows
          camera={{ position: [0, 5, -10], fov: 60 }}
        >
          <color attach="background" />
          <Lighting />
          <Floor />
          <Stage
            intensity={1.2}
            environment="warehouse"
            shadows={{ type: "soft" }}
            adjustCamera={0.8}
          />
          {showAxes && <AxesHelperWithLabels />}{" "}
          {/* Mostra gli assi se showAxes è true */}
          {machineryData.map((machinery, index) => (
            <MachineryModel
              key={index}
              machinery={machinery}
              show3d={show3d}
              realTimeData={realTimeData}
            />
          ))}
          <OrbitControls
            makeDefault
            minPolarAngle={0}
            maxPolarAngle={Math.PI / 2}
            target={[0, 0, 0]} // Set the target to (0, 0, 0) to help orient the scene
            zoomSpeed={1.3} // Adjust the zoom speed
          />
        </Canvas>
      </div>
      <div className="widget" style={{ marginBottom: "20vh", color: "black " }}>
        <h2>Data in Tempo Reale</h2>
        {/* Mostra i dati in tempo reale */}
        {realTimeData.length > 0 ? (
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              fontFamily: "'Courier New', Courier, monospace",
            }}
          >
            <thead>
              <tr>
                {/* Definisci le intestazioni delle colonne */}
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "center",
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  ID
                </th>
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "center",
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  Macchinario
                </th>
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "center",
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  Mesh
                </th>
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "center",
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  Tag Name
                </th>
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "center",
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  WebSocket
                </th>
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "center",
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  Valore
                </th>
              </tr>
            </thead>
            <tbody>
              {realTimeData.map((data, index) => (
                <tr key={index}>
                  {/* Per ogni dato, crea una cella per ogni colonna */}
                  <td
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    {data.id}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    {data.macchinario}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    {data.mesh}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    {data.tag_name}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    {data.websocket}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    {data.valore}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Caricamento dati...</p>
        )}
      </div>
    </div>
  );
};

const AxesHelperWithLabels = () => {
  const textProps = {
    fontSize: 0.5, // Font size for the axis labels
    color: "red", // Text color
  };

  return (
    <>
      {/* AxesHelper */}
      <axesHelper args={[5]} /> {/* The argument is the length of the axes */}
      {/* Add Text for axis labels */}
      <Text position={[5.5, 0, 0]} {...textProps}>
        X
      </Text>
      <Text position={[0, 5.5, 0]} {...textProps}>
        Y
      </Text>
      <Text position={[0, 0, 5.5]} {...textProps}>
        Z
      </Text>
    </>
  );
};

const MachineryModel = ({ machinery, show3d, realTimeData }) => {
  // console.log("Machinery:", machinery);
  const {
    nome_file_jsx,
    posizione_x,
    posizione_y,
    posizione_z,
    rotazione_x,
    rotazione_y,
    rotazione_z,
    nome, // Unique machinery name or ID
  } = machinery;

  const [CurrentModel, setCurrentModel] = useState(null);
  const groupRef = useRef();

  useEffect(() => {
    const loadModel = async () => {
      const model = await show3d(nome_file_jsx);
      setCurrentModel(() => model);
    };

    loadModel();
  }, [nome_file_jsx, show3d]);

  // Filtra i dati in tempo reale per questo macchinario
  useEffect(() => {
    if (!groupRef.current) return;
    // Filtra solo le righe che corrispondono a questo macchinario
    const relevantData = realTimeData.filter((d) => d.macchinario === nome);
    // console.log("Relevant data for", nome, ":", relevantData);
    // Per ogni riga, se è presente una mesh, aggiorna la rotazione della mesh corrispondente
    relevantData.forEach((item) => {
      const { mesh, valore } = item;
      console.log(typeof valore);
      if (mesh && typeof valore === "string") {
        const targetMesh = groupRef.current.getObjectByName(mesh);
        if (targetMesh) {
          // Applica la rotazione sull'asse X in base al valore (in gradi)
          // Convertiamo in radianti
          targetMesh.rotation.x = valore * (Math.PI / 180);
        } else {
          console.warn(`Mesh "${mesh}" non trovata nel macchinario "${nome}"`);
        }
      }
    });
  }, [realTimeData, nome]);

  if (!CurrentModel) return null;

  // Posizionamento e rotazioni iniziali del macchinario
  const position = [posizione_x, posizione_y, posizione_z];
  const rotation = [
    rotazione_x * (Math.PI / 180),
    rotazione_y * (Math.PI / 180),
    rotazione_z * (Math.PI / 180),
  ];

  return (
    <group
      position={position}
      rotation={rotation}
      name={`machinery-${nome}`}
      ref={groupRef}
    >
      <CurrentModel />
    </group>
  );
};

export default Main;
