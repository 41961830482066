import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Loader from "../components/Loader"; // Importa il componente Loader
import {
  fetchPlcMappings,
  fetchDropdownData,
  updatePlcMapping,
  deletePlcMapping, // Importa la funzione deletePlcMapping
} from "./Services";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  connectWebSocket,
  getWebSocketStatus,
} from "../services/WebsocketServices";
import { updateWebSocketValore } from "../services/WebSocketTableState";

const VariabiliPlcPage = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [plcMappings, setPlcMappings] = useState([]);
  const [dropdownData, setDropdownData] = useState({
    macchinari: [],
    meshes: [],
    websockets: [],
  });
  const [loading, setLoading] = useState(true);
  const [editingRow, setEditingRow] = useState(null);
  const [editedRowData, setEditedRowData] = useState({});
  const [tagValues, setTagValues] = useState({}); // Stato per memorizzare i valori dei tag
  const [status, setStatus] = useState("offline");

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      const loadData = async () => {
        try {
          const mappings = await fetchPlcMappings();
          const dropdowns = await fetchDropdownData();
          setPlcMappings(mappings);
          setDropdownData(dropdowns);
        } catch (err) {
          console.error("Errore nel caricamento dei dati", err);
        } finally {
          setLoading(false);
        }
      };
      loadData();
    }
  }, [user, navigate]);

  useEffect(() => {
    const resetState = () => {
      setTagValues({});
    };

    resetState();

    const updateWebSocketStatus = () => {
      const status = getWebSocketStatus();
      setStatus(status);
    };

    const callbacks = {
      onOpen: () => {
        updateWebSocketStatus();
        console.log("WebSocket connesso");
      },
      onMessage: (message) => {
        // console.log("Messaggio ricevuto:", message);
        if (message && message.content) {
          // Aggiorniamo i tagValues per la visualizzazione
          setTagValues((prevValues) => ({
            ...prevValues,
            ...message.content,
          }));

          // Per ogni messaggio ricevuto, aggiorniamo solo il valore del tag
          Object.keys(message.content).forEach((tagName) => {
            updateWebSocketValore(tagName, message.content[tagName]);
          });
        } else {
          console.log("Messaggio ignorato (tipo non corrisponde):", message);
        }
      },
      onError: (error) => console.error("Errore WebSocket:", error),
      onClose: () => updateWebSocketStatus(),
    };

    connectWebSocket(callbacks);

    return () => {
      resetState();
    };
  }, []);

  const startEditingRow = (id) => {
    setEditingRow(id);
    const row = plcMappings.find((r) => r.id === id);
    setEditedRowData({
      macchinario: row.macchinario || "",
      mesh: row.mesh || "",
      websocket: row.websocket || "",
    });
  };

  const stopEditingRow = () => {
    setEditingRow(null);
    setEditedRowData({});
  };

  const handleSaveRow = async (id) => {
    try {
      const macchinario = dropdownData.macchinari.find(
        (item) => item.nome === editedRowData.macchinario
      );
      const mesh = dropdownData.meshes.find(
        (item) => item.nome === editedRowData.mesh
      );
      const websocket = dropdownData.websockets.find(
        (item) => item.name_websocket === editedRowData.websocket
      );

      const isDuplicate = plcMappings.some(
        (row) =>
          row.tag_name === editedRowData.tag_name &&
          row.macchinario === editedRowData.macchinario &&
          row.mesh === editedRowData.mesh
      );

      if (isDuplicate) {
        alert(
          "Errore: questa combinazione di Tag Name, Macchinario e Mesh è già esistente."
        );
        return;
      }

      const updatedRow = {
        ...plcMappings.find((row) => row.id === id),
        macchinario_id: macchinario ? macchinario.id : null,
        mesh_id: mesh ? mesh.id : null,
        websocket_id: websocket ? websocket.id : null,
      };

      await updatePlcMapping(updatedRow);
      alert("Mapping aggiornato con successo!");

      const updatedMappings = await fetchPlcMappings();
      setPlcMappings(updatedMappings);
      stopEditingRow();
    } catch (err) {
      console.error("Errore nell'aggiornamento", err);
    }
  };

  const handleDeleteRow = async (id) => {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler eliminare questa riga?"
    );
    if (confirmDelete) {
      try {
        await deletePlcMapping(id);
        alert("Mappatura eliminata con successo!");

        // Ricarica i dati
        const updatedMappings = await fetchPlcMappings();
        setPlcMappings(updatedMappings);
      } catch (err) {
        console.error("Errore durante l'eliminazione della mappatura", err);
      }
    }
  };

  const renderDropdown = (items, selectedValue, onChange) => (
    <select value={selectedValue} onChange={(e) => onChange(e.target.value)}>
      <option value="">Seleziona</option>
      {items.map((item) => (
        <option key={item.id} value={item.nome || item.name_websocket}>
          {item.nome || item.name_websocket}
        </option>
      ))}
    </select>
  );

  const filterMeshes = (macchinarioNome) => {
    const existingMeshes = plcMappings
      .filter((mapping) => mapping.macchinario === macchinarioNome)
      .map((mapping) => mapping.mesh);

    return dropdownData.meshes.filter(
      (mesh) => !existingMeshes.includes(mesh.nome)
    );
  };

  if (loading)
    return (
      <div className="section-page">
        <Navbar />
        <Sidebar />
        <div className="main-content">
          <Loader />
        </div>
      </div>
    );

  return (
    <div className="section-page">
      <Navbar />
      <Sidebar />
      <div className="main-content">
        <div className="widget">
          <h2 className="machine-title">Variabili PLC</h2>
          <table className="data-table">
            <thead>
              <tr>
                <th>Tag Name</th>
                <th>Macchinario</th>
                <th>Mesh</th>
                <th>WebSocket</th>
                <th>Valore</th>
                <th>Azioni</th>
              </tr>
            </thead>
            <tbody>
              {plcMappings.map((row) => (
                <tr key={row.id}>
                  <td>{row.tag_name}</td>
                  <td>
                    {editingRow === row.id
                      ? renderDropdown(
                          dropdownData.macchinari,
                          editedRowData.macchinario,
                          (value) =>
                            setEditedRowData((prev) => ({
                              ...prev,
                              macchinario: value,
                            }))
                        )
                      : row.macchinario || ""}
                  </td>
                  <td>
                    {editingRow === row.id
                      ? renderDropdown(
                          filterMeshes(editedRowData.macchinario),
                          editedRowData.mesh,
                          (value) =>
                            setEditedRowData((prev) => ({
                              ...prev,
                              mesh: value,
                            }))
                        )
                      : row.mesh || ""}
                  </td>
                  <td>
                    {editingRow === row.id
                      ? renderDropdown(
                          dropdownData.websockets.filter(
                            (ws) =>
                              ws.macchinario_id ===
                              dropdownData.macchinari.find(
                                (m) => m.nome === editedRowData.macchinario
                              )?.id
                          ),
                          editedRowData.websocket,
                          (value) =>
                            setEditedRowData((prev) => ({
                              ...prev,
                              websocket: value,
                            }))
                        )
                      : row.websocket || ""}
                  </td>
                  <td>
                    {/* Mostra il valore di tag_name se presente */}
                    {tagValues[row.tag_name] || "Nessun valore"}
                  </td>
                  <td>
                    {editingRow === row.id ? (
                      <>
                        <button
                          className="save"
                          onClick={() => handleSaveRow(row.id)}
                        >
                          Salva
                        </button>
                        <button className="cancel" onClick={stopEditingRow}>
                          Annulla
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="modify"
                          onClick={() => startEditingRow(row.id)}
                        >
                          Modifica
                        </button>
                        <button
                          className="delete"
                          onClick={() => handleDeleteRow(row.id)}
                        >
                          Elimina
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VariabiliPlcPage;
