// ModaleAdd.js
import React, { useState } from "react";

const ModaleAdd = ({ onClose, onAdd }) => {
  // State to manage form inputs
  const [nome, setNome] = useState("");
  const [note, setNote] = useState("");

  // State to hold the selected files
  const [gltfFile, setGltfFile] = useState(null);
  const [binFile, setbinFile] = useState(null);
  const [jsxFile, setJsxFile] = useState(null);

  // Function to handle form submission
  const handleSave = () => {
    if (!nome) {
      alert("Il nome del macchinario è obbligatorio!");
      return;
    }

    // Create a FormData object to hold form data and files
    const formData = new FormData();
    formData.append("nome", nome);
    formData.append("note", note);

    // Append files if they are selected
    if (gltfFile) {
      formData.append("gltfFile", gltfFile);
    }
    if (binFile) {
      formData.append("binFile", binFile);
    }
    if (jsxFile) {
      formData.append("jsxFile", jsxFile);
    }

    // Pass the FormData to the onAdd function
    onAdd(formData);

    // Close the modal
    onClose();
  };

  return (
    <div className="modal">
      <form onSubmit={(e) => e.preventDefault()}>
        <h2>Aggiungi Nuovo Macchinario</h2>

        <label htmlFor="nome">Nome:</label>
        <input
          id="nome"
          type="text"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />

        <label htmlFor="note">Note:</label>
        <textarea
          id="note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />

        <label htmlFor="gltfFile">Modello GLTF:</label>
        <input
          id="gltfFile"
          type="file"
          accept=".gltf"
          onChange={(e) => setGltfFile(e.target.files[0])}
        />

        <label htmlFor="binFile">Modello BIN:</label>
        <input
          id="binFile"
          type="file"
          accept=".bin"
          onChange={(e) => setbinFile(e.target.files[0])}
        />

        <label htmlFor="jsxFile">Modello JSX:</label>
        <input
          id="jsxFile"
          type="file"
          accept=".jsx"
          onChange={(e) => setJsxFile(e.target.files[0])}
        />

        <button type="button" onClick={handleSave}>
          Aggiungi
        </button>
        <button type="button" className="cancel" onClick={onClose}>
          Annulla
        </button>
      </form>
    </div>
  );
};

export default ModaleAdd;
