import React, { useRef, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three"; // Importa THREE per gestire eventuali modifiche future
import { TextureLoader } from "three"; // Importa il TextureLoader

function Model({ startAnimation, resetAnimation }) {
  const group = useRef();
  const { scene, animations } = useGLTF("/tutorial/connectiongeminitoplc.glb");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    const textureLoader = new TextureLoader(); // Inizializza il caricatore di texture
    const faceTexture = textureLoader.load("/tutorial/face2.png", (texture) => {
      texture.center.set(0.5, 0.5); // Imposta il centro della texture per consentire la rotazione
      texture.rotation = Math.PI; // Ruota di 180 gradi (π radianti)
    });

    scene.traverse((child) => {
      if (child.name === "Plane004_2") {
        child.material = new THREE.MeshStandardMaterial({
          map: faceTexture, // Assegna la texture come mappa
        });
        console.log("Texture 'face2.png' applicata e ruotata a Plane004_2");
      }
    });
  }, [scene]);

  useEffect(() => {
    const textureLoader = new TextureLoader(); // Inizializza il caricatore di texture
    const faceTexture = textureLoader.load("/tutorial/face1.png", (texture) => {
      texture.center.set(0.5, 0.5); // Imposta il centro della texture per consentire la rotazione
      texture.rotation = Math.PI; // Ruota di 180 gradi (π radianti)
    });

    scene.traverse((child) => {
      if (child.name === "Plane003_2") {
        child.material = new THREE.MeshStandardMaterial({
          map: faceTexture, // Assegna la texture come mappa
        });
      }
    });
  }, [scene]);

  useEffect(() => {
    const textureLoader = new TextureLoader(); // Inizializza il caricatore di texture
    const faceTexture = textureLoader.load("/tutorial/face3.png", (texture) => {
      texture.center.set(0.5, 0.5); // Imposta il centro della texture per consentire la rotazione
      texture.rotation = Math.PI; // Ruota di 180 gradi (π radianti)
    });

    scene.traverse((child) => {
      if (child.name === "Plane002_2") {
        child.material = new THREE.MeshStandardMaterial({
          map: faceTexture, // Assegna la texture come mappa
        });
      }
    });
  }, [scene]);

  useEffect(() => {
    scene.traverse((child) => {
      if (child.name === "Server") {
        child.material = new THREE.MeshStandardMaterial({
          color: new THREE.Color(0x808080), // Colore grigio
          metalness: 0.6, // Aspetto metallico
          roughness: 0.4, // Moderata rugosità
          emissive: new THREE.Color(0x1a1a1a), // Emissione grigio scuro
          emissiveIntensity: 0.2, // Intensità leggera dell'emissione
          transparent: true, // Abilita la trasparenza
          opacity: 0.7, // Imposta un livello di trasparenza del 70%
        });
        console.log("Materiale grigio trasparente applicato al server");
      }

      if (
        child.name.startsWith("Sphere") // Riconosce tutte le sfere
      ) {
        child.material = new THREE.MeshStandardMaterial({
          color: new THREE.Color(0x808080), // Colore verde trasparente
          transparent: true,
          opacity: 0.5, // Opacità al 50%
        });
        console.log(`Materiale trasparente applicato a ${child.name}`);
      }
    });
  }, [scene]);

  useEffect(() => {
    scene.traverse((child) => {
      console.log("Oggetto trovato:", child.name);
      if (child.isMesh) {
        if (child.name === "obj15" || child.name === "obj15_8") {
          child.material = new THREE.MeshStandardMaterial({
            color: new THREE.Color(0x4a4a4a), // Colore grigio
            metalness: 0.8, // Materiale metallico (alta riflessione)
            roughness: 0.4, // Bassa rugosità per un aspetto più lucido
            emissive: new THREE.Color(0x1e3a8a), // Dettagli blu come un'emissione (luce riflessa blu)
            emissiveIntensity: 0.2, // Intensità dell'emissione blu
          });
        }
        if (
          child.name === "obj15_6" ||
          child.name === "obj15_7" ||
          child.name === "obj15_8" ||
          child.name === "obj15_9"
        ) {
          child.material = new THREE.MeshStandardMaterial({
            color: new THREE.Color(0x808080), // Colore grigio plastica
            metalness: 0, // Nessuna proprietà metallica (plastica)
            roughness: 0.7, // Superficie ruvida per un aspetto meno lucido
            emissive: new THREE.Color(0x000000), // Nessuna emissione di luce (nessun blu)
            emissiveIntensity: 0, // Nessuna emissione
          });
        }
        if (child.name === "plc") {
          child.material = new THREE.MeshStandardMaterial({
            color: new THREE.Color(0x9a3d3d), // Colore tra rosso e grigio
            transparent: true, // Abilita la trasparenza
            opacity: 0.85, // Opacità 85% (leggera trasparenza)
            roughness: 0.2, // Superficie liscia, bassa rugosità
            metalness: 0.1, // Materiale non metallico
            clearcoat: 0.8, // Effetto di lucidità sopra il materiale
            clearcoatRoughness: 0.1, // Bassa rugosità per una lucentezza più nitida
          });

          console.log("Colore applicato alla mesh 'plc'");
        }
      }
    });
  }, [scene]);

  useEffect(() => {
    if (actions && startAnimation) {
      Object.keys(actions).forEach((key) => {
        const action = actions[key];
        action.stop();
        action.reset();
        action.setLoop(THREE.LoopOnce, 1);
        action.timeScale = 0.5;
        action.play();
        action.clampWhenFinished = true;
        action.onFinished = () => {
          console.log(`Animazione "${key}" completata!`);
        };
        console.log(`Animazione "${key}" trovata, avvio una sola volta.`);
      });
    }
  }, [actions, startAnimation]);

  useEffect(() => {
    if (resetAnimation) {
      console.log("Animazione resettata!");
    }
  }, [resetAnimation]);

  return (
    <group ref={group}>
      <primitive object={scene} />
    </group>
  );
}

function App() {
  const [startAnimation, setStartAnimation] = useState(false);
  const [resetAnimation, setResetAnimation] = useState(false);

  const handleStartAnimation = () => {
    setStartAnimation(true);
  };

  const handleResetAnimation = () => {
    setStartAnimation(false);
    setResetAnimation(true);
    setTimeout(() => {
      setResetAnimation(false);
    }, 100);
  };

  return (
    <div className="main-content">
      <Canvas
        style={{ background: "white" }}
        camera={{ position: [-150, 30, -10], fov: 50 }}
      >
        {/* Luce ambientale per un'illuminazione uniforme */}
        <ambientLight intensity={0.3} />

        {/* Luce direzionale per creare ombre */}
        <directionalLight
          position={[5, 5, 5]}
          intensity={1}
          castShadow
          shadow-mapSize-width={1024} // Risoluzione dell'ombra
          shadow-mapSize-height={1024}
          shadow-camera-near={0.5}
          shadow-camera-far={500}
        />

        {/* Aggiungi un effetto di luce emisferica per un'illuminazione morbida */}
        <hemisphereLight
          skyColor={new THREE.Color(0xaaaaaa)} // Colore del cielo
          groundColor={new THREE.Color(0x444444)} // Colore del suolo
          intensity={0.5}
        />

        {/* Controlli della telecamera */}
        <OrbitControls enablePan={true} enableZoom={true} enableRotate={true} />

        {/* Modello 3D */}
        <Model
          startAnimation={startAnimation}
          resetAnimation={resetAnimation}
        />
      </Canvas>

      {/* Pulsante per avviare l'animazione */}
      <button
        style={{
          position: "absolute",
          top: "20px",
          left: "20px",
          padding: "10px",
          fontSize: "16px",
          cursor: "pointer",
        }}
        onClick={handleStartAnimation}
      >
        Avvia Animazione
      </button>

      {/* Pulsante per riavviare l'animazione */}
      <button
        style={{
          position: "absolute",
          top: "60px",
          left: "20px",
          padding: "10px",
          fontSize: "16px",
          cursor: "pointer",
        }}
        onClick={handleResetAnimation}
      >
        Riavvia Animazione
      </button>
    </div>
  );
}

export default App;
