// MacchinarioTable.js
import React, { useState, useCallback } from "react";
import ModaleUpdate from "./ModaleUpdate";
import ModaleAdd from "./ModaleAdd";
import ModaleMesh from "./ModaleMeshAdd";
import ModaleWebsocket from "./ModaleWebSocket";
import ConfirmModal from "../../components/ConfirmModal";
import Loader from "../../components/Loader"; // Importa il componente Loader
import {
  fetchMeshAssociate,
  deleteMacchinario,
  updateMacchinario,
  addMacchinario,
  addMesh,
  deleteMesh,
  fetchWebsocketMacchinario,
  addWebSocket,
  deleteWebSocket,
} from "../Services";

const MacchinarioTable = ({
  macchinari,
  setMacchinari,
  show3d,
  isDevelopment,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isAddwebsocketOpen, setIsAddwebsocketOpen] = useState(false);
  const [isAddMeshModalOpen, setIsAddMeshModalOpen] = useState(false);
  const [selectedMacchinario, setSelectedMacchinario] = useState(null);
  const [webSockets, setWebSockets] = useState([]);
  const [meshAssociate, setMeshAssociate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    message: "",
    onConfirm: null,
    onCancel: null,
  });

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMacchinario(null);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const closeAddWebsocket = () => {
    setIsAddwebsocketOpen(false);
  };

  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  const openAddMesh = () => {
    setIsAddMeshModalOpen(true);
  };

  const closeAddMeshModal = () => {
    setIsAddMeshModalOpen(false);
  };

  const openAddWebsocket = () => {
    setIsAddwebsocketOpen(true);
  };

  const handleAddWebsocket = (macchinarioId, websocketData) => {
    setConfirmDialog({
      isOpen: true,
      title: "Conferma",
      message: "Sei sicuro di voler aggiungere questo WebSocket?",
      onConfirm: async () => {
        setConfirmDialog({ isOpen: false });
        setLoading(true);
        try {
          const newWebSocket = await addWebSocket(macchinarioId, websocketData);
          setWebSockets((prev) => [...prev, newWebSocket]);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
        closeAddWebsocket();
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const handleSelectMacchinario = useCallback(
    async (macchinario) => {
      setSelectedMacchinario(macchinario);
      setLoading(true);

      try {
        const meshData = await fetchMeshAssociate(macchinario.id);
        setMeshAssociate(meshData);

        const webSocketData = await fetchWebsocketMacchinario(macchinario.id);
        setWebSockets(webSocketData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }

      if (macchinario.nome) {
        show3d(macchinario.nome);
      }
    },
    [show3d]
  );

  const handleAddMesh = (macchinarioId, meshData) => {
    setConfirmDialog({
      isOpen: true,
      title: "Conferma",
      message: "Sei sicuro di voler aggiungere questa mesh?",
      onConfirm: async () => {
        setConfirmDialog({ isOpen: false });
        setLoading(true);
        try {
          const newMesh = await addMesh(macchinarioId, meshData);
          setMeshAssociate((prev) => [...prev, newMesh]);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
        closeAddMeshModal();
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const handleSave = (updatedMacchinario) => {
    if (!updatedMacchinario.nome) {
      return;
    }

    setConfirmDialog({
      isOpen: true,
      title: "Conferma",
      message: "Sei sicuro di voler aggiornare questo macchinario?",
      onConfirm: async () => {
        setConfirmDialog({ isOpen: false });
        setLoading(true);
        try {
          const updated = await updateMacchinario(updatedMacchinario);
          setMacchinari((prev) =>
            prev.map((m) => (m.id === updated.id ? updated : m))
          );
          closeModal();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const handleAddMacchinario = (formData) => {
    if (!formData.get("nome")) {
      return;
    }

    setConfirmDialog({
      isOpen: true,
      title: "Conferma",
      message: "Sei sicuro di voler aggiungere questo macchinario?",
      onConfirm: async () => {
        setConfirmDialog({ isOpen: false });
        setLoading(true);
        try {
          const newMacchinario = await addMacchinario(formData);
          setMacchinari((prev) => [...prev, newMacchinario]);
          setIsAddModalOpen(false);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const handleDeleteWebSocket = (macchinarioId, websocketId) => {
    setConfirmDialog({
      isOpen: true,
      title: "Conferma",
      message: "Sei sicuro di voler eliminare questo WebSocket?",
      onConfirm: async () => {
        setConfirmDialog({ isOpen: false });
        setLoading(true);
        try {
          await deleteWebSocket(macchinarioId, websocketId);
          setWebSockets((prev) => prev.filter((ws) => ws.id !== websocketId));
        } catch (error) {
          console.error("Errore durante l'eliminazione:", error);
        } finally {
          setLoading(false);
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const handleDeleteMacchinario = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Conferma",
      message: "Sei sicuro di voler eliminare questo macchinario?",
      onConfirm: async () => {
        setConfirmDialog({ isOpen: false });
        setLoading(true);
        try {
          await deleteMacchinario(id);
          setMacchinari((prev) => prev.filter((m) => m.id !== id));
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const handleDeleteMesh = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Conferma",
      message: "Sei sicuro di voler eliminare questa mesh?",
      onConfirm: async () => {
        setConfirmDialog({ isOpen: false });
        setLoading(true);
        try {
          await deleteMesh(id);
          setMeshAssociate((prev) => prev.filter((mesh) => mesh.id !== id));
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel: () => setConfirmDialog({ isOpen: false }),
    });
  };

  const columnsMesh = ["Nome", "Tipo", "Movimento"];
  const columnsWebSockets = ["WebSocket Type"];
  const columnsMacchinari = ["Nome", "Nome File GLTF", "Note"];

  const rowsMacchinari = macchinari.map((macchinario) => ({
    id: macchinario.id,
    nome: macchinario.nome,
    nome_file_gltf: macchinario.nome_file_gltf,
    note: macchinario.note,
    ...macchinario,
  }));

  return (
    <div>
      {loading && <Loader />}
      {/* Titolo principale */}
      <div className="widget">
        <h2 className="machine-title">Gestione Macchinari</h2>

        <div className="tables-container">
          {/* Tabella Macchinari */}
          <div className="table-wrapper">
            <h3 className="table-header">
              Elenco Macchinari
              {isDevelopment && (
                <button onClick={openAddModal} className="button">
                  Aggiungi Macchinario
                </button>
              )}
            </h3>
            <table className="data-table">
              <thead>
                <tr>
                  {columnsMacchinari.map((col) => (
                    <th key={col}>{col}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {rowsMacchinari.map((row) => (
                  <tr key={row.id} onClick={() => handleSelectMacchinario(row)}>
                    <td>{row.nome}</td>
                    <td>{row.nome_file_gltf}</td>
                    <td>{row.note}</td>
                    <td>
                      <div className="table-actions">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedMacchinario(row);
                            setIsModalOpen(true);
                          }}
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                        <button
                          className="delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteMacchinario(row.id);
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Sezione dettagli macchinario selezionato */}
          {selectedMacchinario && (
            <>
              {/* Sezione Mesh */}
              <div className="widget">
                <h3>
                  Mesh associate a {selectedMacchinario.nome}
                  <button onClick={openAddMesh} className="button">
                    Aggiungi Mesh
                  </button>
                </h3>
                <table className="data-table">
                  <thead>
                    <tr>
                      {columnsMesh.map((col) => (
                        <th key={col}>{col}</th>
                      ))}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {meshAssociate.map((mesh) => (
                      <tr key={mesh.id}>
                        <td>{mesh.nome}</td>
                        <td>{mesh.tipo}</td>
                        <td>{mesh.tipo_movimento}</td>
                        <td>
                          <div className="table-actions">
                            <button
                              className="delete"
                              onClick={() => handleDeleteMesh(mesh.id)}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Sezione WebSocket */}
              <div className="widget">
                <h3>
                  WebSocket associati a {selectedMacchinario.nome}
                  <button onClick={openAddWebsocket} className="button">
                    Aggiungi WebSocket
                  </button>
                </h3>

                {webSockets.length === 0 ? (
                  <p className="empty-state">
                    Non ci sono Connessioni WebSocket associate al macchinario.
                  </p>
                ) : (
                  <table className="data-table">
                    <thead>
                      <tr>
                        {columnsWebSockets.map((col) => (
                          <th key={col}>{col}</th>
                        ))}
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {webSockets.map((ws) => (
                        <tr key={ws.id}>
                          <td>{ws.name_websocket}</td>
                          <td>
                            <div className="table-actions">
                              <button
                                className="delete"
                                onClick={() =>
                                  handleDeleteWebSocket(
                                    ws.macchinario_id,
                                    ws.id
                                  )
                                }
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {/* Modali */}
      {isModalOpen && (
        <ModaleUpdate
          macchinario={selectedMacchinario}
          closeModal={closeModal}
          onSave={handleSave}
        />
      )}
      {isAddModalOpen && (
        <ModaleAdd onClose={closeAddModal} onAdd={handleAddMacchinario} />
      )}
      {isAddMeshModalOpen && (
        <ModaleMesh
          closeModal={closeAddMeshModal}
          onAddMesh={handleAddMesh}
          macchinarioId={selectedMacchinario?.id}
          macchinarioNomeFile={selectedMacchinario}
        />
      )}
      {isAddwebsocketOpen && (
        <ModaleWebsocket
          closeModal={closeAddWebsocket}
          onAddWebsocket={handleAddWebsocket}
          macchinarioId={selectedMacchinario?.id}
          macchinarioNome={selectedMacchinario?.nome}
        />
      )}
      {/* Finestra di conferma */}
      {confirmDialog.isOpen && (
        <ConfirmModal
          isOpen={confirmDialog.isOpen}
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onCancel={confirmDialog.onCancel}
        />
      )}
    </div>
  );
};

export default MacchinarioTable;
