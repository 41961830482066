import React, { useState } from "react";

const ModaleWebsocket = ({
  closeModal,
  onAddWebsocket,
  macchinarioId,
  webSockets,
  macchinarioNome,
}) => {
  const [nameWebsocket, setNameWebsocket] = useState("");
  const [error, setError] = useState(""); // Stato per il messaggio di errore

  const handleSave = () => {
    // Crea i dati del nuovo WebSocket
    const newWebSocket = {
      name_websocket: nameWebsocket,
      macchinario_id: macchinarioId,
    };

    // Chiamata alla funzione per aggiungere il WebSocket
    onAddWebsocket(macchinarioId, newWebSocket);
    closeModal(); // Chiude il modale dopo l'aggiunta
  };

  return (
    <div className="modal">
      <form onSubmit={(e) => e.preventDefault()}>
        <h2>Aggiungi un nuovo WebSocket a {macchinarioNome}</h2>

        <input
          type="text"
          value={nameWebsocket}
          onChange={(e) => setNameWebsocket(e.target.value)}
          placeholder="Nome del WebSocket"
        />
        {error && <p className="error">{error}</p>}

        <button onClick={handleSave}>Aggiungi WebSocket</button>
        <button onClick={closeModal}>Annulla</button>
      </form>
    </div>
  );
};
export default ModaleWebsocket;
