import React, { useState, useEffect, useRef, useCallback } from "react";
import * as THREE from "three";
import MacchinarioTable from "./MacchinarioTable";
import { fetchMacchinari } from "../Services";
import { Canvas, useThree } from "@react-three/fiber";
import { Stage, OrbitControls } from "@react-three/drei";
let ENV;
try {
  ENV = require("./config").ENV;
} catch (error) {
  console.warn(
    "Il file config.js non esiste o non contiene ENV. Uso 'production' di default."
  );
  ENV = "production"; // Valore predefinito
}
const ModelViewer = ({ CurrentModel }) => {
  const modelRef = useRef();
  const { camera } = useThree();

  // Usa useCallback per memorizzare la funzione
  const adjustCameraToModel = useCallback(() => {
    if (!modelRef.current) return;

    const box = new THREE.Box3().setFromObject(modelRef.current);
    const center = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3());

    const cameraPosition = new THREE.Vector3(
      center.x,
      center.y,
      center.z + size.length() * 1.5
    );

    camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    camera.lookAt(center);
    camera.updateProjectionMatrix();
  }, [camera]); // Aggiungi `camera` come dipendenza

  useEffect(() => {
    adjustCameraToModel(); // La funzione è già memorizzata, basta chiamarla
  }, [CurrentModel, adjustCameraToModel]); // Aggiungi anche `adjustCameraToModel` come dipendenza

  return (
    <group ref={modelRef}>
      <CurrentModel />
    </group>
  );
};

const Main = () => {
  const isDevelopment = ENV === "development";
  const [error, setError] = useState(null);
  const [macchinari, setMacchinari] = useState([]);
  const [CurrentModel, setCurrentModel] = useState(null);
  const [modelError, setModelError] = useState(null);

  useEffect(() => {
    const loadMacchinari = async () => {
      try {
        const data = await fetchMacchinari();
        setMacchinari(data); // Salviamo i dati dei macchinari
      } catch (error) {
        setError("Errore nel caricamento dei macchinari");
      }
    };

    loadMacchinari();
  }, []);

  const show3d = (fileName) => {
    setModelError(null);

    if (!fileName) {
      console.error("Invalid fileName:", fileName);
      setModelError("Model file name is not valid.");
      return;
    }

    import(`../../GLTFJSX/${fileName}.jsx`)
      .then((module) => {
        if (module && module.default) {
          setCurrentModel(() => module.default);
        } else {
          throw new Error("Invalid model component");
        }
      })
      .catch((error) => {
        console.error("Error loading 3D model:", error);
        setModelError(
          `Errore nel caricamento del modello 3D: ${error.message}`
        );
      });
  };

  function Lighting() {
    return (
      <>
        <ambientLight intensity={0.3} />
        <directionalLight position={[50, 100, 50]} intensity={1.5} castShadow />
        <hemisphereLight
          skyColor={0xffffff}
          groundColor={0x000000}
          intensity={0.5}
        />
        <pointLight
          position={[0, 5, 0]}
          intensity={0.7}
          decay={2}
          castShadow
          color="#ffcc66"
        />
      </>
    );
  }

  return (
    <div>
      {error && <p>{error}</p>}
      <MacchinarioTable
        macchinari={macchinari}
        setMacchinari={setMacchinari}
        show3d={show3d}
        isDevelopment={isDevelopment}
      />
      {modelError && (
        <div className="error-message">
          <p>{modelError}</p>
        </div>
      )}
      {CurrentModel && (
        <div className="widget canvas-widget">
          <Canvas shadows camera={{ position: [20, 20, -30], fov: 60 }}>
            <color attach="background" args={["#ffffff"]} />
            <Lighting />
            <Stage
              intensity={1.2}
              environment="warehouse"
              shadows={{ type: "soft" }}
              adjustCamera={false}
            >
              <ModelViewer CurrentModel={CurrentModel} />
            </Stage>
            <OrbitControls
              makeDefault
              minPolarAngle={0}
              maxPolarAngle={Math.PI / 2}
              target={[0, 0, 0]}
            />
          </Canvas>
        </div>
      )}
    </div>
  );
};

export default Main;
