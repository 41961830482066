import React, { useState, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import settings from "../../settings.json"; // Percorso corretto al file settings.json

const ModaleMeshAdd = ({
  closeModal,
  onAddMesh,
  macchinarioId,
  macchinarioNomeFile,
}) => {
  const [nome, setNome] = useState("");
  const [tipo, setTipo] = useState(""); // Tipo Mesh
  const [tipoMovimento, setTipoMovimento] = useState(""); // Tipo di movimento
  const [note, setNote] = useState("");
  const [meshNotes, setMeshNotes] = useState([]); // Stato per memorizzare le note dei mesh

  // Carica il modello GLTF (senza renderizzarlo)
  const { nodes } = useGLTF(`/3D/${macchinarioNomeFile.nome_file_gltf}`);

  useEffect(() => {
    if (nodes) {
      console.log(nodes);
      // Estrai i nomi dei nodi o le note che ti servono
      const notes = Object.keys(nodes).map((nodeKey) => {
        // Supponiamo che i nodi abbiano delle proprietà chiamate 'note' o qualcosa di simile
        // Modifica in base alla struttura effettiva dei dati nel tuo modello
        return nodes[nodeKey].name || nodeKey; // Usa il nome del nodo o il suo ID come fallback
      });
      setMeshNotes(notes); // Aggiorna lo stato con i nomi dei nodi
    }
  }, [nodes]); // Solo quando 'nodes' cambia

  const handleSubmit = () => {
    if (!nome || !tipo) {
      alert("Please fill in all required fields!");
      return;
    }

    // Crea il nuovo oggetto mesh
    const newMesh = { nome, tipo, tipo_movimento: tipoMovimento, note };
    onAddMesh(macchinarioId, newMesh); // Passa alla funzione onAddMesh
    closeModal(); // Chiudi il modale dopo il salvataggio
  };

  return (
    <div className="modal">
      <form onSubmit={(e) => e.preventDefault()}>
        <h2>Aggiungi Mesh</h2>

        <label htmlFor="nome">Nome Mesh:</label>
        <input
          id="nome"
          type="text"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />

        <label htmlFor="tipo">Tipo Mesh:</label>
        <select
          id="tipo"
          value={tipo}
          onChange={(e) => setTipo(e.target.value)}
        >
          <option value="">Seleziona Tipo</option>
          <option value="Statica">Statica</option>
          <option value="Dinamica">Dinamica</option>
        </select>

        <label htmlFor="tipoMovimento">Tipo di Movimento:</label>
        <select
          id="tipoMovimento"
          value={tipoMovimento}
          onChange={(e) => setTipoMovimento(e.target.value)}
        >
          <option value="">Seleziona Tipo di Movimento</option>
          {settings.movimenti.map((movimento, index) => (
            <option key={index} value={movimento}>
              {movimento}
            </option>
          ))}
        </select>

        {/* Mostra le note (nodi) caricate dal modello */}
        <label htmlFor="note">Note:</label>
        <textarea
          id="note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Seleziona una nota dal modello"
        />
        <div>
          <label>Mesh disponibili:</label>
          <ul className="notes-list">
            {meshNotes.length > 0 ? (
              meshNotes.map((note, index) => <li key={index}>{note}</li>)
            ) : (
              <p>Nessuna nota trovata nel modello.</p>
            )}
          </ul>
        </div>

        <div className="modal-actions">
          <button type="button" onClick={handleSubmit}>
            Salva
          </button>
          <button type="button" className="cancel" onClick={closeModal}>
            Annulla
          </button>
        </div>
      </form>
    </div>
  );
};

export default ModaleMeshAdd;
