let tableData = [];

// Funzione per caricare i dati da localStorage
function loadTableDataFromStorage() {
  const storedData = localStorage.getItem("tableData");
  if (storedData) {
    // Se ci sono dati salvati, caricali come oggetti JSON
    tableData = JSON.parse(storedData);
  }
}

// Imposta i dati della tabella
export function setTableData(newData) {
  // Assicurati che ogni riga abbia una proprietà 'valore', se non esiste
  const dataWithValue = newData.map((row) => ({
    ...row,
    valore: row.valore !== undefined ? row.valore : null, // Imposta 'null' se 'valore' non esiste
  }));

  tableData = dataWithValue;

  // Salva i dati aggiornati nel localStorage
  localStorage.setItem("tableData", JSON.stringify(tableData));

  // console.log("Table data set:", tableData); // Aggiungi per il debug
}

// Ottiene i dati della tabella
export function getTableData() {
  return tableData;
}

export function updateWebSocketValore(tagName, valore) {
  // Ottieni i dati attuali della tabella
  let currentData = getTableData();

  // Trova la riga con il tag_name corrispondente e aggiorna solo quella
  const updatedData = currentData.map((row) => {
    if (row.tag_name === tagName) {
      // Aggiorna solo la colonna 'valore' per la riga specifica
      return { ...row, valore }; // Mantieni gli altri campi invariati
    }
    return row; // Mantieni le altre righe invariati
  });

  // Imposta i nuovi dati aggiornati nella tabella e salvali in localStorage
  setTableData(updatedData);
}

// Carica i dati da localStorage all'inizio
loadTableDataFromStorage();
