import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { fetchSettings, updateSetting } from "./Services";
import Loader from "../components/Loader"; // Importa il componente Loader
import {
  connectWebSocket,
  getWebSocketStatus,
} from "../services/WebsocketServices";
import Lottie from "lottie-react";
import animationData from "../icons/wifiloop.json"; // Importa l'animazione Lottie

const ImpostazioniPage = () => {
  const [showPassword, setShowPassword] = useState(false);

  // Funzione per alternare la visibilità della password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // Stato per memorizzare le impostazioni
  const [settings, setSettings] = useState([]);

  // Stato per gestire il caricamento dei dati
  const [loading, setLoading] = useState(true);

  // Stato per gestire eventuali errori
  const [error, setError] = useState(null);

  // Stato per lo stato del WebSocket
  const [webSocketStatus, setWebSocketStatus] = useState("CLOSED");

  // Stato per lo stato di ricezione (ad esempio, per aggiornare la UI con eventuali messaggi)
  const [receivingState, setReceivingState] = useState("");

  // Funzione per aggiornare lo stato del WebSocket
  const updateWebSocketStatus = () => {
    const status = getWebSocketStatus(); // Ottieni lo stato corrente del WebSocket
    setWebSocketStatus(status); // Aggiorna lo stato
  };

  // Connessione al WebSocket al montaggio del componente
  useEffect(() => {
    connectWebSocket({
      onOpen: () => {
        setWebSocketStatus("OPEN"); // Imposta lo stato a "OPEN" quando la connessione è stabilita
      },
      onClose: () => {
        setWebSocketStatus("CLOSED"); // Imposta lo stato a "CLOSED" quando la connessione viene chiusa
      },
      onError: () => {
        setWebSocketStatus("ERROR"); // Imposta lo stato a "ERROR" in caso di errore
      },
      onMessage: (event) => {
        // Qui puoi gestire il messaggio ricevuto dal WebSocket
        setReceivingState(event.data); // Imposta lo stato di ricezione
      },
    });

    const intervalId = setInterval(updateWebSocketStatus, 1000); // Aggiorna ogni secondo

    return () => {
      clearInterval(intervalId); // Pulisci l'intervallo quando il componente viene smontato
    };
  }, []);

  // Recupera le impostazioni quando il componente è montato
  useEffect(() => {
    const loadSettings = async () => {
      try {
        const data = await fetchSettings(); // Chiamata alla funzione fetchSettings
        // console.log("Impostazioni caricate:", data);

        // Popola lo stato con i dati
        const updatedSettings = data.map((setting) => ({
          ...setting, // Aggiungi direttamente i dati in "settings"
          value: setting.value || "", // Imposta un valore vuoto di default se il valore è mancante
          description: setting.description || "", // Imposta un valore vuoto di default se la descrizione è mancante
        }));

        setSettings(updatedSettings);
      } catch (err) {
        setError("Errore nel caricamento delle impostazioni");
      } finally {
        setLoading(false); // Nasconde il loading quando i dati sono stati caricati
      }
    };

    loadSettings();
  }, []); // [] indica che viene eseguito solo una volta al caricamento del componente

  // Funzione per gestire il cambiamento nei campi di input
  const handleInputChange = (e, id) => {
    const { name, value } = e.target;
    setSettings((prevSettings) =>
      prevSettings.map((setting) =>
        setting.id === id ? { ...setting, [name]: value } : setting
      )
    );
  };

  // Funzione per salvare le modifiche
  const handleSaveChanges = async () => {
    try {
      for (const setting of settings) {
        await updateSetting(setting.id, {
          key: setting.key,
          description: setting.description,
          value: setting.value,
        });
      }
      alert("Impostazioni aggiornate con successo!");
    } catch (err) {
      setError("Errore durante il salvataggio delle modifiche.");
    }
  };

  // Gestione del loading o errore
  if (loading) {
    return <Loader />; // Usa il componente Loader per il caricamento
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  // Determina il colore della pallina in base allo stato del WebSocket
  const statusColor =
    webSocketStatus === "OPEN"
      ? "bg-green-500"
      : webSocketStatus === "CLOSED"
      ? "bg-gray-500"
      : "bg-red-500";

  return (
    <div className="section-page">
      <Navbar />
      <Sidebar />
      <div className="main-content">
        {/* WebSocket Status */}
        <div className="widget">
          <div className="header flex justify-between items-center px-5 py-5">
            <h2>Impostazioni</h2>
            <button onClick={handleSaveChanges} className="save">
              Salva modifiche
            </button>
          </div>
          <div className="flex items-center justify-between px-10 py-10">
            <p className="text-lg font-semibold text-gray-800">WebSocket</p>
            <div className="flex items-center space-x-2">
              <div>
                {webSocketStatus === "OPEN" ? (
                  <Lottie
                    animationData={animationData} // Usa il file Lottie per l'animazione
                    loop={true}
                    autoplay={true}
                    style={{ width: "30px", height: "30px" }} // Dimensioni dell'animazione
                  />
                ) : (
                  <div className={`w-3 h-3 rounded-full ${statusColor}`} />
                )}
              </div>
              <p style={{ marginLeft: "5px" }}>
                {webSocketStatus === "OPEN" ? "Connesso" : "Non connesso"}
              </p>
            </div>
          </div>

          {settings.length === 0 ? (
            <p>Non ci sono impostazioni disponibili.</p>
          ) : (
            settings.map((setting) => (
              <div key={setting.id} className="settings-section">
                <h2>{setting.description}</h2>

                <div className="input-container">
                  <div>
                    <label htmlFor={`key-${setting.id}`}>Key:</label>
                    <input
                      type="text"
                      id={`key-${setting.id}`}
                      name="key"
                      value={setting.key}
                      onChange={(e) => handleInputChange(e, setting.id)}
                      className="section-input"
                      disabled
                    />
                  </div>

                  <div>
                    <label htmlFor={`value-${setting.id}`}>Value:</label>
                    <div className="password-input-wrapper">
                      <input
                        type={showPassword ? "text" : "password"} // Imposta il tipo in base a showPassword
                        id={`value-${setting.id}`}
                        name="value"
                        value={setting.value}
                        onChange={(e) => handleInputChange(e, setting.id)}
                        className="section-input"
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="toggle-password"
                      >
                        {showPassword ? "Hide" : "Show"}{" "}
                        {/* Mostra/nascondi testo dell'icona */}
                      </button>
                    </div>
                  </div>

                  <div className="description-input">
                    <label htmlFor={`description-${setting.id}`}>
                      Description:
                    </label>
                    <input
                      type="text"
                      id={`description-${setting.id}`}
                      name="description"
                      value={setting.description}
                      onChange={(e) => handleInputChange(e, setting.id)}
                      className="section-input"
                    />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ImpostazioniPage;
