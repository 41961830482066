import axios from "axios";

// Variabile per memorizzare l'API_URL
let API_URL = "";

// Funzione per ottenere dinamicamente l'API_URL dal backend
function getAPIUrl() {
  console.log("Chiamando il backend per ottenere l'API_URL...");

  return axios
    .get("https://backend.geminiautomation.it/api/settings/settings")
    .then((response) => {
      console.log("Risposta dal backend:", response.data);

      // Cerca un'impostazione con la chiave 'API_URL'
      const apiUrlSetting = response.data.find(
        (setting) => setting.key === "API_URL"
      );

      if (apiUrlSetting) {
        console.log("API_URL trovata nel backend:", apiUrlSetting.value);
        return apiUrlSetting.value; // Se trovata, restituisci il valore
      } else {
        console.log(
          "Impostazione 'API_URL' non trovata nel backend, usando il valore di fallback."
        );
        return "https://localhost:5000/api"; // URL di fallback
      }
    })
    .catch((error) => {
      console.error(
        "Errore nella richiesta GET per le impostazioni API:",
        error
      );
      return "https://backend.geminiautomation.it/api"; // URL di fallback
    });
}

// Tentativo di importare il file di configurazione dinamicamente
try {
  const config = require("./config_api.js"); // Usa require per caricare il file dinamicamente
  if (config.API_URL) {
    API_URL = config.API_URL; // Se l'URL esiste nel file, usalo
    console.log("API_URL trovato nel file di configurazione:", API_URL);
  } else {
    console.log("API_URL non trovato nel file di configurazione.");
  }
} catch (error) {
  API_URL = await getAPIUrl(); // Chiamata al backend per ottenere l'API_URL
  console.log("config_api.js non trovato, chiamo il backend.");
}

const token = localStorage.getItem("token");
console.log("Token recuperato da localStorage:", token);

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Controlla se l'errore è 404, in tal caso non fare log
    if (error.response && error.response.status === 404) {
      return Promise.reject(error); // Non loggare gli errori 404, semplicemente rifiuta la promise
    }

    // Log dettagliato dell'errore per altri tipi di errore
    console.log("API request failed. Error details:");
    console.error("Error message:", error.message);
    console.error("Error code:", error.code);
    console.error("Error response:", error.response); // Potresti voler verificare il dettaglio della risposta dell'errore, se disponibile

    // Verifica se l'errore è un errore di rete
    if (error.code === "ERR_NETWORK") {
      console.log("Network error detected");
      console.error(
        "Network error, check your connection or server availability"
      );
    }

    // Se il server non risponde, mostra un messaggio personalizzato
    if (!error.response) {
      console.error(
        "No response from the server. Please check server availability."
      );
    }

    // Log dell'URL dell'API per il contesto
    console.log("API URL in caso di errore:", API_URL);

    return Promise.reject(error); // Ritorna l'errore per la gestione successiva
  }
);
export default api;
