// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import PrivateRoute from "./components/PrivateRoute";
import AccessDeniedPage from "./pages/AccessDeniedPage";
import VirtualCommissioningPage from "./pages/VirtualCommissioningPage";
import RealTimeMonitoringPage from "./pages/RealTimeMonitoringPage";
import PredictiveMaintenancePage from "./pages/PredictiveMaintenancePage";
import VirtualControlPage from "./pages/VirtualControlPage";
import VariabiliPlcPage from "./pages/VariabiliPlcPage";
import ImpostazioniPage from "./pages/ImpostazioniPage";
import GemelloDigitalePage from "./pages/GemelloDigitalePage";
import ConnectionToPlc from "./pages/ConnectionToPlc";
import Loader from "./components/Loader";
import { useEffect } from "react";

function App() {
  function RedirectToExternal() {
    useEffect(() => {
      window.location.href = "https://geminiautomation.framer.website/";
    }, []);

    return Loader;
  }

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/tutorial/connection-to-plc"
            element={<ConnectionToPlc />}
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute requiredRoles={["Admin"]}>
                <DashboardPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/virtual-commissioning"
            element={
              <PrivateRoute requiredRoles={[]}>
                <VirtualCommissioningPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/real-time-monitoring/modello-analitico"
            element={
              <PrivateRoute requiredRoles={[]}>
                <RealTimeMonitoringPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/real-time-monitoring/gemello-digitale"
            element={
              <PrivateRoute requiredRoles={[]}>
                <GemelloDigitalePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/predictive-maintenance"
            element={
              <PrivateRoute requiredRoles={[]}>
                <PredictiveMaintenancePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/virtual-control"
            element={
              <PrivateRoute requiredRoles={[]}>
                <VirtualControlPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/variabili-plc"
            element={
              <PrivateRoute requiredRoles={[]}>
                <VariabiliPlcPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/impostazioni"
            element={
              <PrivateRoute requiredRoles={["Admin"]}>
                <ImpostazioniPage />
              </PrivateRoute>
            }
          />
          <Route path="/access-denied" element={<AccessDeniedPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
