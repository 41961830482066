// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import {
  login as loginService,
  logout as logoutService,
} from "../services/auth";
import { jwtDecode } from "jwt-decode"; // Importazione corretta

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Inizialmente true

  // Recupera i dati utente al caricamento
  useEffect(() => {
    const token = localStorage.getItem("token");
    const roles = JSON.parse(localStorage.getItem("roles"));
    const username = localStorage.getItem("username");
    const expirationDate = new Date(localStorage.getItem("expirationDate"));

    if (token && expirationDate > new Date()) {
      try {
        setUser({ token, roles, username });
      } catch (error) {
        console.error("Errore nella decodifica del token:", error);
        logout();
      }
    }
    setLoading(false); // Loading termina una volta terminata la verifica
  }, []);

  const login = async (username, password) => {
    setLoading(true); // Mostra loader durante il login
    try {
      const data = await loginService(username, password);
      const { access_token, roles } = data;
      const decodedToken = jwtDecode(access_token);
      const expirationDate = new Date(decodedToken.exp * 1000);

      // Memorizza il token e i ruoli nel localStorage
      localStorage.setItem("token", access_token);
      localStorage.setItem("roles", JSON.stringify(roles));
      localStorage.setItem("username", username);
      localStorage.setItem("expirationDate", expirationDate.toISOString());

      // Imposta lo stato dell'utente
      setUser({ token: access_token, roles, username });
    } catch (error) {
      console.error("Login fallito:", error);
      throw new Error("Login fallito");
    } finally {
      setLoading(false); // Nasconde loader dopo il login
    }
  };

  const logout = () => {
    logoutService();
    setUser(null);
    localStorage.clear();
    setLoading(false);
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
