import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaDesktop,
  FaEye,
  FaWrench,
  FaCog,
  FaPlug,
  FaTools,
  FaClone, // Icona per Gemello Digitale
  FaChartLine, // Icona per Modello Analitico
  FaBars, // Icona Hamburger
} from "react-icons/fa"; // Import delle icone

const Sidebar = () => {
  const location = useLocation(); // Hook per ottenere l'URL corrente
  const [isMonitoringOpen, setIsMonitoringOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Stato per la sidebar

  // Funzione per gestire il toggle del sotto-menu
  const toggleMonitoring = () => {
    setIsMonitoringOpen((prev) => !prev);
  };

  // Funzione per gestire l'apertura e chiusura della sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  // Effetto per aprire automaticamente il sotto-menu quando ci troviamo nelle sottopagine
  useEffect(() => {
    if (
      location.pathname === "/real-time-monitoring/gemello-digitale" ||
      location.pathname === "/real-time-monitoring/modello-analitico"
    ) {
      setIsMonitoringOpen(true);
    }
  }, [location.pathname]); // Si attiva solo quando cambia l'URL

  return (
    <>
      {/* Sidebar */}
      <aside className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <ul>
          <li>
            <Link to="/virtual-commissioning" className="sidebar-link">
              <FaDesktop className="icon" /> Virtual Commissioning
            </Link>
          </li>
          <li>
            <div
              className="sidebar-link"
              style={{
                cursor: "pointer",
                marginLeft: "8px",
                fontSize: "0.9rem",
              }}
              onClick={toggleMonitoring}
            >
              <FaEye className="icon" /> Real-Time Monitoring
            </div>
            {isMonitoringOpen && (
              <ul className="submenu">
                <li>
                  <Link
                    to="/real-time-monitoring/gemello-digitale"
                    className="sidebar-link"
                  >
                    <FaClone className="icon" /> Gemello Digitale
                  </Link>
                </li>
                <li>
                  <Link
                    to="/real-time-monitoring/modello-analitico"
                    className="sidebar-link"
                  >
                    <FaChartLine className="icon" /> Modello Analitico
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link to="/predictive-maintenance" className="sidebar-link">
              <FaWrench className="icon" /> Predictive Maintenance
            </Link>
          </li>
          <li>
            <Link to="/virtual-control" className="sidebar-link">
              <FaCog className="icon" /> Virtual Control
            </Link>
          </li>
          <li>
            <Link to="/variabili-plc" className="sidebar-link">
              <FaPlug className="icon" /> Esposizione variabili PLC
            </Link>
          </li>
          <li>
            <Link to="/impostazioni" className="sidebar-link">
              <FaTools className="icon" /> Impostazioni
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
