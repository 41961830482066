import React from "react";
import { tw } from "twind";
import { GeneralNode } from "./GeneralNode";
import { WebSocketNode } from "./WebsocketNode";
import { AlertNode } from "./AlertNode";

export default function CustomNode({ id, data }) {
  const { name_websocket = [] } = data; // Recupera gli websocket dal nodo
  console.log("CustomNode", data);

  return (
    <div
      className={tw(
        "relative w-[1200px] h-auto rounded-lg shadow-inner grid grid-cols-3 gap-4 p-4"
      )}
    >
      <GeneralNode id={id} data={data} />
      {/* Genera dinamicamente WebSocketNode in base agli websocket */}
      <div className={tw("col-span-2 grid grid-cols-2 gap-2")}>
        {name_websocket.length > 0 ? (
          name_websocket.map((ws, index) => (
            // Passa solo ws a WebSocketNode, non tutto il data
            <WebSocketNode key={index} name_websocket={ws} />
          ))
        ) : (
          <p>No WebSocket nodes available</p> // Mostra un messaggio nel caso name_websocket sia vuoto
        )}
      </div>
    </div>
  );
}
