import React from "react";
import ReactDOM from "react-dom/client"; // Importa `createRoot` da `react-dom/client`
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Crea un tema personalizzato
const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff", // Colore principale bianco per il background e il testo
    },
    secondary: {
      main: "#f5f5f5", // Colore secondario marrone chiaro
    },
    background: {
      default: "#f5f5f5", // Colore di sfondo grigio chiaro
    },
    text: {
      primary: "#0rr003", // Colore del testo in nero
    },
  },
});

// Usa `createRoot` per inizializzare l'app in React 18
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);
