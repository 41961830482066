import React, { useState, useEffect } from "react";

const ModaleUpdate = ({ macchinario, closeModal, onSave }) => {
  const [nome, setNome] = useState(macchinario?.nome || "");
  const [nome_file_gltf, setNomegltf] = useState(
    macchinario?.nome_file_gltf || ""
  );
  const [note, setNote] = useState(macchinario?.note || "");
  const [posizioneX, setPosizioneX] = useState(macchinario?.posizione_x || 0);
  const [posizioneY, setPosizioneY] = useState(macchinario?.posizione_y || 0);
  const [posizioneZ, setPosizioneZ] = useState(macchinario?.posizione_z || 0);
  const [rotazioneX, setRotazioneX] = useState(macchinario?.rotazione_x || 0);
  const [rotazioneY, setRotazioneY] = useState(macchinario?.rotazione_y || 0);
  const [rotazioneZ, setRotazioneZ] = useState(macchinario?.rotazione_z || 0);

  useEffect(() => {
    if (macchinario) {
      setNome(macchinario.nome || "");
      setNomegltf(macchinario.nome_file_gltf || "");
      setNote(macchinario.note || "");
      setPosizioneX(macchinario.posizione_x || 0);
      setPosizioneY(macchinario.posizione_y || 0);
      setPosizioneZ(macchinario.posizione_z || 0);
      setRotazioneX(macchinario.rotazione_x || 0);
      setRotazioneY(macchinario.rotazione_y || 0);
      setRotazioneZ(macchinario.rotazione_z || 0);
    }
  }, [macchinario]);

  const handleSubmit = () => {
    const updatedMacchinario = {
      ...macchinario,
      nome,
      nome_file_gltf,
      note,
      posizione_x: posizioneX,
      posizione_y: posizioneY,
      posizione_z: posizioneZ,
      rotazione_x: rotazioneX,
      rotazione_y: rotazioneY,
      rotazione_z: rotazioneZ,
    };
    onSave(updatedMacchinario);
    closeModal();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h3>Modifica Macchinario</h3>
        <form>
          <div className="form-group">
            <label>Nome:</label>
            <input
              type="text"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Nome File GLTF:</label>
            <input
              type="text"
              value={nome_file_gltf}
              onChange={(e) => setNomegltf(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Note:</label>
            <textarea value={note} onChange={(e) => setNote(e.target.value)} />
          </div>

          {/* Posizione Inputs - Allineati Orizzontalmente */}
          <div className="position-input">
            <div className="form-group">
              <label>Posizione (X):</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  value={posizioneX}
                  onChange={(e) => setPosizioneX(parseFloat(e.target.value))}
                  step="0.01"
                  min="-100"
                  max="100"
                  className="number-input"
                />
                <span className="unit">m</span>
              </div>
            </div>
            <div className="form-group">
              <label>Posizione (Y):</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  value={posizioneY}
                  onChange={(e) => setPosizioneY(parseFloat(e.target.value))}
                  step="0.01"
                  min="-100"
                  max="100"
                  className="number-input"
                />
                <span className="unit">m</span>
              </div>
            </div>
            <div className="form-group">
              <label>Posizione (Z):</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  value={posizioneZ}
                  onChange={(e) => setPosizioneZ(parseFloat(e.target.value))}
                  step="0.01"
                  min="-100"
                  max="100"
                  className="number-input"
                />
                <span className="unit">m</span>
              </div>
            </div>
          </div>

          {/* Rotazione Inputs - Allineati Orizzontalmente */}
          <div className="rotation-input">
            <div className="form-group">
              <label>Rotazione (X):</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  value={rotazioneX}
                  onChange={(e) => setRotazioneX(parseFloat(e.target.value))}
                  step="1"
                  min="-180"
                  max="180"
                  className="number-input"
                />
                <span className="unit">°</span>
              </div>
            </div>
            <div className="form-group">
              <label>Rotazione (Y):</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  value={rotazioneY}
                  onChange={(e) => setRotazioneY(parseFloat(e.target.value))}
                  step="1"
                  min="-180"
                  max="180"
                  className="number-input"
                />
                <span className="unit">°</span>
              </div>
            </div>
            <div className="form-group">
              <label>Rotazione (Z):</label>
              <div className="input-wrapper">
                <input
                  type="number"
                  value={rotazioneZ}
                  onChange={(e) => setRotazioneZ(parseFloat(e.target.value))}
                  step="1"
                  min="-180"
                  max="180"
                  className="number-input"
                />
                <span className="unit">°</span>
              </div>
            </div>
          </div>

          <div className="modal-actions">
            <button type="button" className="cancel" onClick={closeModal}>
              Annulla
            </button>
            <button type="button" onClick={handleSubmit}>
              Salva
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModaleUpdate;
