import React, { useState, useEffect, useCallback } from "react";
import {
  ReactFlow,
  Background,
  Controls,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { fetchMacchinari, fetchWebsocketMacchinario } from "../Services"; // Function to fetch machinery data
import CustomNode from "./CustomNode"; // Import the custom node

function Main() {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  useEffect(() => {
    const fetchNodes = async () => {
      try {
        const data = await fetchMacchinari();

        const verticalSpacing = 900; // Vertical spacing between nodes

        // Mappa i macchinari con i websocket associati
        const mappedNodes = await Promise.all(
          data.map(async (node, index) => {
            const websockets = await fetchWebsocketMacchinario(node.id); // Fetch websocket
            const randomX = Math.floor(Math.random() * 601) - 10;

            return {
              id: node.id.toString(),
              data: {
                ...node, // Spread the entire node object
                name_websocket: websockets, // Include WebSocket data
              },
              position: { x: randomX, y: verticalSpacing * index },
              type: "custom", // Use the custom node type
            };
          })
        );

        setNodes(mappedNodes);

        // Create edges to connect nodes sequentially (no cycle closure)
        const dynamicEdges = mappedNodes
          .map((node, index) => {
            const nextIndex = index + 1;
            if (nextIndex < mappedNodes.length) {
              return {
                id: `e${node.id}-${mappedNodes[nextIndex].id}`,
                source: node.id,
                target: mappedNodes[nextIndex].id,
                animated: true, // Animated edge for better visibility
                style: { strokeWidth: 4 }, // Increased stroke width (default is 2)
              };
            }

            return null; // No edge for the last node
          })
          .filter(Boolean); // Filter out null edges

        setEdges(dynamicEdges); // Add dynamic edges
      } catch (error) {
        console.error("Error fetching nodes:", error);
      }
    };

    fetchNodes(); // Load nodes and edges on mount
  }, []);

  const onNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const onEdgesChange = useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  return (
    <div className="widget">
      <div className="light" style={{ height: "85vh" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={{ custom: CustomNode }} // Add the custom node type
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          fitView
          style={{ width: "100%", height: "100%" }}
        >
          <Background color="black" variant="dots" />
          <Controls />
        </ReactFlow>
      </div>
    </div>
  );
}

export default Main;
