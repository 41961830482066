import axios from "axios";
import { updateWebSocketValore } from "./WebSocketTableState";

// Funzione per ottenere l'URL del WebSocket dal backend
async function getWSUrl() {
  try {
    // Richiesta al backend per ottenere le impostazioni
    const response = await axios.get(
      "https://backend.geminiautomation.it/api/settings/settings"
    );

    // Cerca un'impostazione con la chiave 'WS_URL'
    const wsUrlSetting = response.data.find(
      (setting) => setting.key === "WS_URL"
    );

    if (wsUrlSetting) {
      console.log("WS_URL trovata nel backend:", wsUrlSetting.value);
      return wsUrlSetting.value; // Se trovata, restituisci il valore
    } else {
      console.log(
        "Impostazione 'WS_URL' non trovata nel backend, usando il valore di fallback."
      );
      return "ws://localhost:8000"; // URL di fallback
    }
  } catch (error) {
    console.error(
      "Errore nella richiesta GET per le impostazioni WS_URL:",
      error
    );
    return "ws://localhost:8000"; // URL di fallback in caso di errore
  }
}

// Carica la configurazione dal file di configurazione, se disponibile
let config;
try {
  config = require("./config_api");
} catch (e) {
  config = {}; // Se il file non esiste, config sarà un oggetto vuoto
}

// Determina l'URL del WebSocket, usando la configurazione o richiamando la funzione getWSUrl
let websocketUrl = config.WS_URL || (await getWSUrl());

console.log("URL del WebSocket:", websocketUrl);

// Variabili per la gestione del WebSocket
let socket = null;
let websocketStatus = "CLOSED"; // Stato iniziale della connessione WebSocket
let callbacks = []; // Array per memorizzare le callback

export let isWebSocketOpen = false; // Initially false until WebSocket connects
let pendingMessages = []; // Array per memorizzare i messaggi in attesa

export function waitForWebSocketOpen() {
  return new Promise((resolve, reject) => {
    if (isWebSocketOpen) {
      resolve(); // If already open, resolve immediately
    } else if (socket && socket.readyState === WebSocket.OPEN) {
      resolve(); // If WebSocket is open, resolve immediately
    } else {
      const interval = setInterval(() => {
        if (isWebSocketOpen) {
          clearInterval(interval); // Stop checking once connected
          resolve(); // Resolve the Promise when WebSocket is open
        }
      }, 1000); // Check every 1000ms
    }
  });
}

// Funzione per connettere il WebSocket e gestire i messaggi
export function connectWebSocket(userCallbacks = {}) {
  // Aggiungi i nuovi callback
  callbacks.push(userCallbacks);

  // Se il WebSocket è già stato creato, non fare nulla
  if (socket) {
    return;
  }

  // Crea una nuova connessione WebSocket
  socket = new WebSocket(websocketUrl);

  // Gestione dell'apertura della connessione
  socket.onopen = () => {
    websocketStatus = "OPEN"; // Stato WebSocket aggiornato
    isWebSocketOpen = true;
    callbacks.forEach((callback) => callback.onOpen && callback.onOpen());

    // Invia i messaggi in attesa se la connessione è stata stabilita
    if (pendingMessages.length > 0) {
      pendingMessages.forEach((msg) => {
        socket.send(JSON.stringify(msg));
      });
      pendingMessages = []; // Pulisci i messaggi in attesa
    }
  };

  // Gestione dei messaggi ricevuti
  socket.onmessage = (event) => {
    try {
      // Prova a fare il parsing del messaggio come JSON
      const message = JSON.parse(event.data);
      // console.log("Messaggio JSON ricevuto:", message);

      // Passa il messaggio parsato alle callback
      callbacks.forEach(
        (callback) => callback.onMessage && callback.onMessage(message)
      );

      // Aggiorna solo il valore del tag per ogni messaggio ricevuto
      if (message && message.content) {
        Object.keys(message.content).forEach((tagName) => {
          updateWebSocketValore(tagName, message.content[tagName]);
        });
      } else {
        console.log("Messaggio ignorato (tipo non corrisponde):", message);
      }
    } catch (error) {
      console.error("Errore nel parsing del messaggio JSON:", error);
    }
  };

  // Gestione degli errori
  socket.onerror = (error) => {
    console.error("Errore WebSocket:", error);
    callbacks.forEach(
      (callback) => callback.onError && callback.onError(error)
    );
  };

  // Gestione della chiusura della connessione
  socket.onclose = (event) => {
    websocketStatus = "CLOSED"; // Stato WebSocket aggiornato
    isWebSocketOpen = false; // La connessione è ora chiusa
    console.log("Connessione WebSocket chiusa.", event);
    callbacks.forEach(
      (callback) => callback.onClose && callback.onClose(event)
    );
  };
}

// Funzione per ottenere lo stato della connessione WebSocket
export function getWebSocketStatus() {
  return websocketStatus;
}

// Funzione per inviare un messaggio al WebSocket
export function sendMessage(message) {
  if (isWebSocketOpen) {
    socket.send(JSON.stringify(message));
    console.log("Messaggio inviato:", message);
  } else {
    console.error(
      "WebSocket non connesso. Messaggio in attesa di connessione."
    );
    pendingMessages.push(message); // Memorizza il messaggio fino a quando la connessione non è pronta
  }
}

// Funzione per chiudere la connessione WebSocket
export function closeWebSocket() {
  if (socket) {
    socket.close();
    socket = null;
    websocketStatus = "CLOSED"; // Stato WebSocket aggiornato
    console.log("Connessione WebSocket chiusa.");
  } else {
    console.log("Nessuna connessione WebSocket attiva.");
  }
}
