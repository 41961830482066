import React from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Main from "./GemelloDigitale/Main";

const GemelloDigitalePage = () => {
  return (
    <div className="section-page">
      <Navbar />
      <Sidebar />
      <div className="main-content">
        <Main />
      </div>
    </div>
  );
};

export default GemelloDigitalePage;
