// src/pages/AccessDeniedPage.js
import React from "react";
import { useNavigate } from "react-router-dom";

const AccessDeniedPage = () => {
  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate("/login"); // Naviga alla pagina di login
  };

  return (
    <div className="access-denied-page">
      <div className="access-denied-container">
        <h2 className="access-denied-title">Accesso Negato</h2>
        <p className="access-denied-message">
          Non hai i permessi necessari per accedere a questa pagina.
        </p>
        <div className="button-group">
          <button className="login-button" onClick={handleBackToLogin}>
            Torna al login
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccessDeniedPage;
