// src/hooks/useAuth.js
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const useAuth = () => {
  const { user, loading, login, logout } = useContext(AuthContext); // Includi logout

  // Funzione per verificare se l'utente ha uno dei ruoli richiesti
  const hasRequiredRole = (requiredRoles) => {
    if (!user || !user.roles) return false;
    return requiredRoles.some((role) => user.roles.includes(role));
  };

  return {
    user,
    loading,
    login,
    logout, // Esponi logout
    hasRequiredRole,
  };
};

export default useAuth;
