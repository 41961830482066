// src/pages/DashboardPage.js
import React, { useContext, useEffect } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Main from "./VirtualCommissioning/Main";

const VirtualCommissioningPage = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  // Se l'utente non è loggato, redirigi al login
  useEffect(() => {
    if (!user) {
      navigate("/login"); // Se non c'è l'utente, redirigi al login
    }
  }, [user, navigate]);

  return (
    <div className="section-page">
      <Navbar />
      <Sidebar />
      <div className="main-content">
        <Main />
      </div>
    </div>
  );
};

export default VirtualCommissioningPage;
