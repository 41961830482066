// Services.js
import api from "../services/api";
import {
  sendMessage,
  waitForWebSocketOpen,
} from "../services/WebsocketServices";
import { setTableData } from "../services/WebSocketTableState";

export const fetchMacchinari = async () => {
  try {
    const response = await api.get("/virtual_commissioning/macchinari", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data; // Return the fetched data
  } catch (err) {
    console.error("Errore nel caricamento dei macchinari", err);
    throw err;
  }
};

export const fetchSettings = async () => {
  try {
    const response = await api.get("/settings/settings", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (err) {
    console.error("Errore nel caricamento delle impostazioni", err);
    throw err;
  }
};

export const fetchPlcMappings = async () => {
  try {
    const response = await api.get("/plc_tag_mapping/plc_tag", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    setTableData(response.data); // Set the WebSocket table data
    return response.data;
  } catch (err) {
    console.error("Errore nel caricamento delle mappature PLC", err);
    throw err; // Propaga l'errore per una gestione successiva
  }
};

// Fetch dei dati per i dropdown
export const fetchDropdownData = async () => {
  try {
    const response = await api.get("/plc_tag_mapping/dropdown_data", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (err) {
    console.error("Errore nel caricamento dei dati del dropdown", err);
    throw err;
  }
};

// Aggiornamento della mappatura PLC
export const updatePlcMapping = async (mapping) => {
  try {
    const response = await api.put("/plc_tag_mapping/update", mapping, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    // Ensure WebSocket is open before sending message
    await waitForWebSocketOpen(); // Wait for WebSocket connection

    sendMessage({
      type: "config_hardware_updated", // Tipo del messaggio
    });

    return response.data;
  } catch (err) {
    console.error("Errore durante l'aggiornamento della mappatura PLC", err);
    throw err;
  }
};

export const deletePlcMapping = async (id) => {
  try {
    const response = await api.delete(`/plc_tag_mapping/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json", // Assicura il tipo di contenuto
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(
      "Errore durante l'eliminazione della mappatura PLC:",
      error.response ? error.response.data : error.message
    );
    throw error; // Propaga l'errore per gestirlo altrove, se necessario
  }
};

export const fetchWebsocketMacchinario = async (macchinarioId) => {
  try {
    // Effettua la richiesta GET per ottenere i WebSocket associati al macchinario
    const response = await api.get(
      `/virtual_commissioning/macchinari/${macchinarioId}/websockets`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Aggiungi il token JWT per l'autenticazione
        },
      }
    );

    // Restituisci la lista di WebSocket associati al macchinario
    return response.data;
  } catch (err) {
    if (err.response && err.response.status === 404) {
      return []; // Restituisce una lista vuota se non ci sono WebSocket
    } else {
      console.error(
        "Errore nel caricamento dei WebSocket del macchinario",
        err
      );
      throw err; // Propaga l'errore per una gestione successiva
    }
  }
};

// Funzione per aggiornare un'impostazione
export const updateSetting = async (id, updatedData) => {
  try {
    const response = await api.put(`/settings/settings/${id}`, updatedData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data; // Return the updated data or success message
  } catch (err) {
    console.error("Errore nell'aggiornamento dell'impostazione", err);
    throw err;
  }
};

// Function to update a macchinario
export const updateMacchinario = async (updatedMacchinario) => {
  try {
    const response = await api.put(
      `/virtual_commissioning/macchinari/${updatedMacchinario.id}`,
      updatedMacchinario,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data.macchinario; // Return the updated macchinario
  } catch (error) {
    console.error("Errore durante l'aggiornamento:", error);
    throw error;
  }
};

// Function to add a new macchinario
export const addMacchinario = async (formData) => {
  try {
    const response = await api.post(
      "/virtual_commissioning/macchinari",
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data.macchinario; // Return the new macchinario
  } catch (error) {
    console.error("Errore durante l'aggiunta:", error);
    throw error;
  }
};

// Function to delete a macchinario
export const deleteMacchinario = async (id) => {
  try {
    await api.delete(`/virtual_commissioning/macchinari/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    console.error("Errore durante l'eliminazione:", error);
    throw error;
  }
};
export const deleteWebSocket = async (macchinarioId, websocketId) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("No token found");
    return;
  }
  console.log(
    `/virtual_commissioning/macchinari/${macchinarioId}/websockets/${websocketId}`
  );

  try {
    const response = await api.delete(
      `/virtual_commissioning/macchinari/${macchinarioId}/websockets/${websocketId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Check for successful deletion (status 200)
    if (response.status === 200) {
      console.log("WebSocket deleted successfully");
      return response.data; // Success data, if necessary
    }

    console.error("Failed to delete WebSocket", response.data);
  } catch (error) {
    console.error(
      "Error during WebSocket deletion:",
      error.response ? error.response.data : error.message
    );
    throw error; // Propagate error to the caller
  }
};

export const fetchMeshAssociate = async (macchinarioId) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("No token found");
    return [];
  }

  try {
    // Effettua la chiamata GET all'API
    const response = await api.get(
      `/virtual_commissioning/mesh_associate/${macchinarioId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Verifica che la risposta abbia lo status 200 (OK)
    if (response.status !== 200) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    // Verifica che i dati siano presenti nella risposta
    if (!response.data || !Array.isArray(response.data)) {
      throw new Error("Invalid data format in response");
    }

    // Mappa i dati per aggiungere variabile_plc e tipo_movimento direttamente nella risposta
    const updatedMeshData = response.data.map((mesh) => {
      // Aggiungi variabile_plc e tipo_movimento insieme alla risposta
      return {
        ...mesh,
        variabile_plc:
          mesh.variabile_plc !== "None" ? mesh.variabile_plc : "N/A",
        tipo_movimento:
          mesh.tipo_movimento !== "None" ? mesh.tipo_movimento : "N/A",
      };
    });

    // Restituisci la risposta con i dati aggiornati
    return updatedMeshData;
  } catch (error) {
    // Gestisci l'errore in caso di problemi nella chiamata API
    console.error("Errore nel recuperare le mesh:", error);
    throw error; // Propaga l'errore per una gestione successiva
  }
};

// Function to add a mesh
export const addMesh = async (macchinarioId, meshData) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("No token found");
    return null;
  }

  try {
    // Invia i dati al backend
    const response = await api.post(
      "/virtual_commissioning/mesh_associate",
      {
        macchinario_id: macchinarioId,
        ...meshData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Verifica che la risposta sia stata restituita correttamente
    if (response.status === 201) {
      const { mesh, movimento } = response.data; // De-strutturazione della risposta

      // Ritorna entrambi i dati (mesh e movimento)
      return {
        ...mesh,
        tipo_movimento: movimento.tipo_movimento, // Aggiungi tipo_movimento direttamente nella risposta della mesh
        movimento_id: movimento.id, // Puoi aggiungere altri dati come movimento_id se necessario
      };
    } else {
      throw new Error(`API request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error("Errore durante l'aggiunta della mesh:", error);
    throw error; // Propaga l'errore per una gestione successiva
  }
};

export const deleteMesh = async (meshId) => {
  try {
    await api.delete(`/virtual_commissioning/mesh_delete/${meshId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    console.error("Errore nell'eliminazione della mesh:", error);
    throw error;
  }
};

// Function to add a WebSocket to a macchinario
export const addWebSocket = async (macchinarioId, websocketData) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("No token found");
    return null;
  }

  try {
    // Send a POST request to the backend to add a WebSocket
    const response = await api.post(
      `/virtual_commissioning/macchinari/${macchinarioId}/websockets`,
      websocketData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Check the response status (201 indicates successful creation)
    if (response.status === 201) {
      return response.data; // Return the new WebSocket data
    } else {
      throw new Error(`API request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error("Errore durante l'aggiunta del WebSocket:", error);
    throw error; // Propagate the error to be handled elsewhere if needed
  }
};
