import React from "react";
import { Handle } from "@xyflow/react";
import { tw } from "twind";

export function GeneralNode({ id, data }) {
  // Crea una copia di 'data' escludendo la chiave 'name_websocket'
  const filteredData = Object.entries(data)
    .filter(([key]) => key !== "name_websocket") // Filtra la chiave 'name_websocket'
    .map(([key, value]) => ({ key, value })); // Mappa gli altri dati

  return (
    <div
      className={tw(
        "rounded-md bg-white shadow-xl max-w-[90%] h-auto w-full p-4"
      )}
    >
      <Handle className={tw("w-2 h-2")} type="target" position="top" />

      <p
        className={tw(
          "rounded-t-md px-2 py-1 bg-pink-500 text-white text-xs leading-tight",
          "text-center"
        )}
        style={{ fontSize: "clamp(0.6rem, 0.8vw, 0.9rem)" }}
      >
        {data.nome || "Senza Nome"}
      </p>

      {filteredData.map(({ key, value }) => (
        <div
          key={key}
          className={tw(
            "flex py-1 text-gray-800 text-xs border-b border-gray-300 mx-1"
          )}
        >
          <span
            className={tw("font-semibold mr-1")}
            style={{ fontSize: "clamp(0.6rem, 0.7vw, 0.8rem)" }}
          >
            {key}:
          </span>
          <span
            className={tw("pl-1 break-words text-wrap leading-tight")}
            style={{
              wordWrap: "break-word",
              maxWidth: "calc(100% - 30px)",
              fontSize: "clamp(0.6rem, 0.7vw, 0.8rem)",
            }}
          >
            {typeof value === "string" ? (
              value.length > 40 ? (
                <>
                  {value.slice(0, 40)}...
                  <br />
                  {value.slice(40)}
                </>
              ) : (
                value
              )
            ) : value !== null && value !== undefined ? (
              String(value)
            ) : (
              "N/A"
            )}
          </span>
        </div>
      ))}

      <Handle className={tw("w-2 h-2")} type="source" position="bottom" />
    </div>
  );
}
