import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Lottie from "lottie-react"; // Importazione corretta
import animationData from "../icons/share-hover.json"; // Assicurati che il percorso sia corretto

const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        {/* Usa Lottie per l'animazione */}
        <span>{user && user.username}</span>
        <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
          className="lottie-icon"
        />
      </div>
      <button onClick={handleLogout} className="logout-button">
        Logout
      </button>
    </nav>
  );
};

export default Navbar;
