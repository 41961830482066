import React, { useEffect, useState } from "react";
import { tw } from "twind";
import {
  connectWebSocket,
  getWebSocketStatus,
} from "../../services/WebsocketServices";

export function WebSocketNode({ name_websocket }) {
  const websocket_name = name_websocket.name_websocket || "";
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState("offline");
  const [lastReceivedTime, setLastReceivedTime] = useState(null);
  const [receivingState, setReceivingState] = useState("Nessuna Ricezione");

  useEffect(() => {
    const resetState = () => {
      setMessage(null);
      setStatus("offline");
      setLastReceivedTime(null);
      setReceivingState("Nessuna Ricezione");
    };

    resetState();

    const updateWebSocketStatus = () => {
      const status = getWebSocketStatus();
      setStatus(status);
    };

    const callbacks = {
      onOpen: () => {
        updateWebSocketStatus();
        console.log("WebSocket connesso");
      },
      onMessage: (message) => {
        // console.log("Messaggio ricevuto:", message); // Log per vedere il messaggio intero
        if (message && message.type === websocket_name) {
          // console.log("Messaggio WebSocket corrisponde al tipo:", message); // Log per confermare che il tipo è corretto
          setMessage(message.content);
          const currentTime = new Date();
          setLastReceivedTime(currentTime);
          setReceivingState("In ricezione...");
        } else {
          // console.log("Messaggio ignorato (tipo non corrisponde):", message); // Log per messaggi ignorati
        }
      },
      onError: (error) => console.error("Errore WebSocket:", error),
      onClose: () => updateWebSocketStatus(),
    };

    connectWebSocket(callbacks);

    return () => {
      resetState();
    };
  }, [websocket_name]);

  useEffect(() => {
    if (!lastReceivedTime) return;
    const timeDifference = (new Date() - lastReceivedTime) / 1000;
    if (timeDifference < 5) {
      setReceivingState("In ricezione...");
    } else {
      const minutes = Math.floor(timeDifference / 60);
      const seconds = Math.floor(timeDifference % 60);
      setReceivingState(`${minutes > 0 ? `${minutes}m ` : ""}${seconds}s fa`);
    }
  }, [lastReceivedTime]);

  const statusColor = status === "OPEN" ? "bg-green-500" : "bg-red-500";

  return (
    <div
      className={tw(
        "rounded-md bg-white shadow-sm max-w-[90%] w-full p-4 text-black"
      )}
    >
      <div className={tw("flex items-center justify-between px-3 py-2")}>
        <p className={tw("text-lg font-semibold text-gray-800")}>
          <p>{name_websocket.name_websocket || "Nome non disponibile"}</p>
        </p>
        <div className={tw("flex items-center space-x-2")}>
          <div
            className={tw(
              `w-3 h-3 rounded-full ${statusColor} ${
                status === "OPEN" ? "animate-bounce" : ""
              }`
            )}
          />
          <span className={tw("text-sm font-medium text-gray-500")}>
            {status === "OPEN" ? "Connesso" : "Non connesso"}
          </span>
        </div>
      </div>
      <div className={tw("px-4 py-2 text-sm text-gray-700 space-y-2")}>
        <div className={tw("flex items-center space-x-2")}>
          <span className={tw("font-semibold")}>Stato ricezione:</span>
          <span>{receivingState}</span>
        </div>
      </div>
      {receivingState === "In ricezione..." && message && (
        <div className={tw("px-4 py-2 text-sm text-gray-700 space-y-2")}>
          <span className={tw("font-semibold")}>Logs:</span>
          <pre
            className={tw(
              "bg-gray-100 p-4 rounded-md font-mono text-sm text-gray-700 border border-gray-300 overflow-x-auto"
            )}
          >
            {JSON.stringify(message, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
}
