import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Loader from "./Loader"; // Assicurati di avere un componente Loader

const PrivateRoute = ({ children, requiredRoles }) => {
  const { user, loading, hasRequiredRole } = useAuth();

  if (loading) {
    return <Loader />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (requiredRoles.length > 0 && !hasRequiredRole(requiredRoles)) {
    // Se l'utente non ha i ruoli richiesti, reindirizza alla pagina di accesso negato
    return <Navigate to="/access-denied" />;
  }

  // Se tutto è a posto, rendi il componente figlio
  return children;
};

export default PrivateRoute;
